import React from "react";
// import vision from "../../assest/Images/vision.svg";
// import mission from "../../assest/Images/mission.svg";
import PrivacyPolicy from "../../assest/Images/PrivacyPolicy.svg";
// import aboutUs from "../../assest/Images/aboutus.jpg";
import MainAnimation from "../../component/mainPageAnimation/mainAnimation"; // Import Sections component
// import Button from "react-bootstrap/Button";

function PP() {
  return (
    <>
      <section className="main-bg">
        <MainAnimation />
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-5 col-md-5">
              <h1 className="home_heading mb-3">Privacy Policy</h1>
              {/* <p className="home-para">
                It has survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p> */}
            </div>
            <div className="col-lg-5 col-md-6 banner-main-image d-flex justify-content-center">
              <img src={PrivacyPolicy} alt="webdevelopement" />
            </div>
          </div>
        </div>
      </section>
      <div className="container my-5">
        <p>
          This Privacy Policy (“Policy”) governs the manner in which SevenUnique
          Tech Solutions Pvt. Ltd (the “Company” or “We”) collects, gains access
          to, uses maintains, and discloses information including personal
          information of the users (each, a “User”) of various services
          (“Services”) provided by the Company, either through the website of
          the Company or through mobile applications or network telecom
          operators. Your Privacy is of utmost importance to us and protection
          of your Personal Data is a key commitment for us. Please note that the
          scope of this Policy is limited to the aforesaid information collected
          or received by the Company through your use of the Services. By using
          the Service, you are expressing your agreement to this Policy and the
          processing of your information, including your personal information,
          in the manner provided in this Policy. If you do not agree to these
          terms, please do not use the Service and do not provide the
          permissions when specifically asked by the Company. What do we
          collect? We may collect and/or gain access to and/or record certain
          information including personal information from or of User in a
          variety of ways, including, but not limited to, when User access and
          uses the Services and in connection with other activities, services,
          features or resources we make available on the Services. These
          information may relate to and include but not limited to your name,
          email address, address, phone number, your unique device ID
          (persistent / non-persistent), hardware type, international mobile
          equipment identity (“IMEI”), the version of your operating system
          (“OS”), your device name, your email address (if you have connected to
          Facebook or Google+), and your location (based on your Internet
          Protocol (“IP”) address), contact list (on your mobile phone),
          applications installed by User through our mobile application,
          applications uninstalled by User, all other applications installed by
          User on his/her device, foreground running apps/process (RUN event; We
          update server about the app downloaded/installed by our mobile
          application), read SMS, network information, User behaviour analysis,
          demographic information (such as preferences and interests etc),
          Credit/Debit Card information of User for Internet banking (We do not
          store credit/debit card information on our servers) [hereinafter
          referred to as “Personal Information”]. Users can always refuse to
          supply Personal Information; however, it may prevent them from
          engaging in certain Services related activities. Usage of Personal
          Information- Your personal information may be used to- Administer the
          Services. Personalize the Services for User. End to User direct links
          to the Services. Process transactions. Process installation. Send
          newsletter, selective offers and promotions. Develop, deliver, and
          improve our products, services, content, and advertising. Auditing,
          data analysis, and research to improve the Services. Troubleshooting
          and helping us to understand usage trends. Send alerts to User.
          Marketing and promotion of the Services. Monitoring and reviewing
          services from time to time Collection and use of non-personal
          information We may collect non-personal information about Users
          whenever they use and interact with the Services. Non-personal
          information may include the browser name, the type of computer, and
          technical information about means used by the User to connect to our
          Services, such as the information about the operating system and the
          internet service providers utilized and other similar information.<br/><br/>
          This information is aggregated and used to help us provide more useful
          information to the User and to understand which part of the Services,
          its products, and services are of most interest. If we do combine
          non-personal information with Personal Information, the combined
          information will be treated as Personal Information for as long as it
          remains combined. We may share Personal Information as well as
          non-personal information of the User with the courts, police
          authorities, or any other government/regulatory/statutory authority,
          in case these are required by them in relation to any proceedings
          pending before them. We are committed to ensuring that the User’s
          Personal Information is secure. We adopt appropriate data collection,
          storage, and processing practices and security measures to protect
          against unauthorized access, alteration, disclosure, or destruction of
          the User's Personal Information, username, password, transaction
          information, and data stored in our Services. When a User uses
          services and products offered by us through the Services, the Personal
          Information shared by the User is visible to the other users and can
          be read, collected, or used by them. The User is responsible for the
          Personal Information User chooses to submit in these instances. For
          example, if the User lists his/her name and email address in a
          blog/forum posting, that information is public. Users are requested to
          take care when using these features.<br/><br/> A cookie is a small file that
          asks permission to be placed on the User’s desktop/laptop/mobile
          device's memory drive. Once the User agrees, the file is added and the
          cookie helps analyse web traffic. Cookies allow Services to respond to
          the User as an individual. The Services can tailor its operations to
          the User’s needs, likes, and dislikes by gathering and remembering
          information about the User’s preferences. Cookies help us better
          understand User’s behaviour, and tell us which parts of the Services
          Users have visited, and facilitates and measure the effectiveness of
          products and services offered by us. We treat information collected by
          cookies and other technologies as non-personal information.<br/><br/> However,
          to the extent that internet protocol (IP) addresses or similar
          identifiers are considered Personal Information by local law, we also
          treat these identifiers as Personal Information. We use traffic log
          cookies to identify which pages are being used. This helps us analyse
          data about web page traffic and improve our Services in order to
          tailor it to customer needs. We only use this information for
          statistical analysis purposes and then the data is removed from the
          system. Overall, cookies help us provide Users with a better
          experience of the Services, by enabling us to monitor which pages
          Users find useful and which the Users do not. A cookie in no way gives
          us access to the User’s desktop/laptop/mobile device or any
          information about the User, other than the data a User chooses to
          share with us.<br/><br/> A User can choose to accept or decline cookies. Most
          web browsers automatically accept cookies, but a User can usually
          modify its browser setting to decline cookies if the user prefers.
          This may prevent the User from taking full advantage of the Services.
          Sharing of information with Authorities The Personal Information of
          the Users is shared as allowed under applicable laws, after following
          due diligence, assessing the recipient’s privacy policies and
          practices, and in line with the purposes set out in this policy.
          Personal Information is shared only after a contractual obligation is
          imposed on the recipients to adhere to similar or no less stringent
          privacy practices of SevenUnique Tech Solutions Pvt. Ltd. <br/><br/> We may share
          your Personal Information in the course of your transaction with
          different categories of recipients such as business partners, service
          providers, merchants, affiliates, associates, subsidiaries, legally
          recognized authorities, regulatory bodies, financial institutions,
          internal teams such as marketing, security, investigation team etc.<br/><br/>
          Personal Information will be shared, as applicable, on need-to-know
          basis, for the following purposes: <li> for enabling the provision of the
          services availed by you and facilitating the services between you and
          the service provider, as requested </li><li> for complying with applicable
          laws as well as meeting the Know Your Customer (KYC) requirements as
          mandated by various regulatory bodies, whose regulated service/product
          you opt through our services/Platforms </li><li> for completing a payment
          transaction initiated by you on a merchant site, where based on your
          instructions, the merchant requests to fetch your Personal Information
          from us </li><li> for the purpose of processing your financial product
          subscription requests placed with us and ensuring that these requests
          reach the relevant financial institution whose service/product you
          have opted for </li><li> if it is required by financial institutions to
          verify, mitigate or prevent fraud or to manage risk or recover funds
          in accordance with applicable laws/regulations </li><li> for services related
          to communication, marketing, data and information storage,
          transmission, security, analytics, fraud detection, risk assessment
          and research </li><li> enforce our Terms or Privacy Policy; respond to claims
          that an advertisement, posting or other content violates the rights of
          a third party; or protect the rights, property or personal safety of
          our users or the general public </li><li> if required to do so by law or in
          good faith we believe that such disclosure is reasonably necessary to
          respond to subpoenas, court orders, or other legal process </li><li> with the
          internal investigation department within SevenUnique Tech Solutions
          Pvt. Ltd. or agencies appointed by SevenUnique Tech Solutions Pvt.
          Ltd. for investigation purposes located within or outside the Indian
          jurisdiction </li><li> should we (or our assets) plan to merge with, or be
          acquired by any business entity, or re-organization, amalgamation,
          restructuring of our business then with such other business entity
          While the information is shared, SevenUnique Tech Solutions Pvt. Ltd.
          ensures that the processing is carried out only on behalf of
          SevenUnique Tech Solutions Pvt. Ltd. and this policy is applicable to
          the extent possible.</li><br/><br/> Security SevenUnique Tech Solutions Pvt. Ltd
          takes various steps and measures to protect the security of the
          Personal Data of its users from misuse, loss, unauthorised access,
          modification or disclosure. We use latest secure server layers
          encryption and access control on our systems. Our safety and security
          processes are audited by a third-party cyber security audit agency
          from time to time. We also ensure the device binding so that the same
          login cannot be used on different device without any additional
          authentication/OTP. The users are advised to not share their login,
          password and OTP details with anybody. How we use cookies? "Cookies"
          are small files placed on your device hard-drive/storage that assist
          us in providing our services. Cookies do not contain any of your
          Personal Information. We may use cookies to give the users a better
          experience while using our website. We will ask for your consent to
          our use of cookies the first time that you enter our website. We use
          cookies or similar technologies on certain pages of the Platform to
          help analyse our web page flow, measure promotional effectiveness, and
          promote trust and safety. We offer certain features that are only
          available through the use of a "cookie" or similar technologies. We
          also use cookies to allow you to enter your password less frequently
          during a session. Cookies or similar technologies can also help us
          provide information that is targeted to your interests. Most cookies
          are "session cookies," meaning that they are automatically deleted
          from your device hard-drive/storage at the end of a session. You are
          always free to decline/delete our cookies or similar technologies if
          your browser/device permits, although in that case you may not be able
          to use certain features on the Platform and you may be required to
          re-enter your password more frequently during a session.<br/><br/> Additionally,
          you may encounter "cookies" or other similar technologies on certain
          pages of the Platform that are placed by third parties. We do not
          control the use of cookies by third parties. Retention of Personal
          Information We will retain User’s Personal Information for the period
          necessary to fulfil the purposes outlined in this Policy unless a
          longer retention period is required or permitted by law.<br/><br/> Sharing
          Personal Information We may use third-party service providers to help
          us operate our business and Services or administer activities on our
          behalf, such as sending out newsletters or surveys. We may share the
          User’s Personal Information with these third parties for those limited
          purposes. Third party websites Users may find advertising or other
          content on the Services that link to the sites and services of our
          partners, suppliers, advertisers, sponsors, licensors, and other third
          parties. We do not control the content or links that appear on these
          sites and services and are not responsible for the practices employed
          by those websites and services linked to or from our Services. In
          addition, these sites and services, including their content and links,
          may be constantly changing. These sites and services may have their
          own privacy policies and customer service policies. Browsing and
          interaction on any other website, including websites and services
          which have a link to the Services, is subject to that website and
          services’ terms and policies.<br/><br/> Advertisement appearing on the Services
          may be delivered to the User by advertising partners, who may set
          cookies. These cookies allow the ad server to recognize the User
          computer each time they send the User an online advertisement to
          compile non-personal information about the User or others who use the
          User computer. This information allows advertisement networks to,
          among other things, deliver targeted advertisements that they believe
          will be of most interest to the User. This Policy does not cover the
          use of cookies by any advertisers. The Company has the discretion to
          update this Policy at any time. We encourage User to frequently check
          this page for any changes to stay informed about how we are helping to
          protect the Personal Information we collect. User acknowledges and
          agrees that it is his/her responsibility to review this Policy
          periodically and become aware of modifications.<br/><br/> By using the Services,
          User signifies his/her acceptance of this Policy. If the User does not
          agree to this Policy, please do not use the Services. User continued
          use of the Services following the posting of changes to this Policy
          will be deemed User acceptance of those changes. Advertising We may
          share non-personally identifiable information with advertisers. We
          also allow specific partners to collect information from your browser
          or device for advertising and measurement purposes using their own
          cookies or similar technologies.<br/><br/> Choice/Opt-out We provide all users
          with the opportunity to opt-out of receiving any of our services or
          non-essential (promotional, marketing-related) communications from us,
          after setting up an account. If you want to remove your contact
          information from all our lists and newsletters or discontinue any our
          services, please click on the unsubscribe button on the emailers.
          Personal Information Access and Rectification You can access and
          review your Personal Information shared by you by logging in to
          SevenUnique Tech Solutions Pvt. Ltd Platform using your secure login
          credentials and may also place a request for updating your Personal
          Information by writing to us. SevenUnique Tech Solutions Pvt. Ltd may
          need to request specific information from you to confirm your identity
          and ensure authentication to correctly update your Personal
          Information. This is a security measure to ensure that Personal
          Information is not disclosed to any person who does not have a right
          to receive it or is not incorrectly modified. In case, where you need
          any further information specific to the product/ services that you are
          availing or our use of your Personal Information, we request you to
          read through the Terms and Conditions specific to the product/service
          which is easily accessible through the SevenUnique Tech Solutions Pvt.
          Ltd Platform. Children Information We do not knowingly solicit or
          collect Personal Information from children under the age of 18 and use
          of our Platform is available only to persons who can form a legally
          binding contract under the Indian Contract Act, 1872. If you are under
          the age of 18 years then you must use the Platform or services under
          the supervision of your parent, legal guardian, or any responsible
          adult. Changes to Policy Since our business changes constantly, so
          will our policies. We reserve the right, at our sole discretion, to
          change, modify, add, or remove portions of this Privacy Policy at any
          time without any prior written notice to you. We may, however,
          reasonably endeavour to notify you of the changes, it is your
          responsibility to review the Privacy Policy periodically for
          updates/changes. Your continued use of our services/Platform,
          following the posting of changes will mean that you accept and agree
          to the revisions. We will never make changes to policies in order to
          make it less protective of Personal Information already shared by you.
        </p>
      </div>
    </>
  );
}

export default PP;
