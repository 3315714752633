import React from "react";
// import vision from "../../assest/Images/vision.svg";
// import mission from "../../assest/Images/mission.svg";
import TermPolicy from "../../assest/Images/Term&Policy.svg";
// import aboutUs from "../../assest/Images/aboutus.jpg";
import MainAnimation from "../../component/mainPageAnimation/mainAnimation"; // Import Sections component
// import Button from "react-bootstrap/Button";

function TC() {
  return (
    <>
      <section className="main-bg">
        <MainAnimation />
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-5 col-md-5">
              <h1 className="home_heading mb-3">
                INTRODUCTION ON THE SCOPE OF THIS TERMS AND CONDITONS
              </h1>
              {/* <p className="home-para">
                It has survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p> */}
            </div>
            <div className="col-lg-6 col-md-6 banner-main-image d-flex justify-content-center">
              <img src={TermPolicy} alt="webdevelopement" />
            </div>
          </div>
        </div>
      </section>
     
      <div className="container my-5">
      <h2 className="fw-bold">Terms And Conditions</h2>
      <ul>
        <li> SEVENUNIQUE is a digital financial facilitation service provided by
        SEVENUNIQUE TECH SOLUTIONS PVT LTD. Company registered under the
        Companies Act having registered office AT 213, 2nd floor Pushp enclave
        Tonk Road </li> <li> Pratap Nagar Jaipur Rajasthan- 302033 (hereinafter referred
        to as the “Company”, which expression shall unless repugnant to the
        context or meaning thereof mean and include its successors and permitted
        assigns). </li> <li>  Under SEVENUNIQUE, the company inter-alia provides Digital
        Banking & Payments industry to its customers in Indiaby facilitating
        dissemination of digital financial services provided by various service
        providers including but not limited to services like Assisted Digital
        Financial Services like , SMS Payment, Khata Service, Utility Payment,
        Prepaid Cards, Insurance, and Money Transfer by SEVENUNIQUE App.
        (Company Services)  </li><li> The terms and conditions subject to which Company
        Services are provided to the Consumers (as defined below) are mentioned
        herein and the use of Company Service by Consumers is subject to this
        Consumers’ acceptance of the following terms and conditions (as may be
        amended from time to time) (“Agreement”/ “Terms of Use”). </li> <li>  As an
        Applicant (as defined below), You may apply to open a SEVENUNIQUE
        Membership Account (hereinafter referred as “Membership Account” /
        “SEVENUNIQUE Account”) and avail “Company Services” by: (a) depending on
        online channel of registration of the Applicant, providing its details
        in the prescribed application format (“AF”) either by himself or with
        the assistance from the Retail Partner on this Platform (as defined
        below); and (b) by accepting and subscribing this Terms of Use by
        clicking on “I Agree” button at the end of this Terms of Use (below).
        Your acceptance of these terms and conditions shall be unconditional and
        without any limitation or qualification. </li><li>  Please carefully read these
        terms of use. By providing your consent and/or using this platform you
        indicate your understanding and acceptance of these terms and
        conditions. If you do not agree to these terms of services you may not
        use this platform </li> <li>  You understand, agree and acknowledge that Your
        setting up of Membership Account, use of Company Services, use of this
        Platform, Website and/or Company Services including all information,
        tools and services available from this Platform to You is conditioned
        upon Your acceptance of all terms, conditions, policies and notices
        stated here, the terms whereof are subject to change at any time,
        without prior notice to You. Any new features or tools which are added
        to the current Platform shall also be subject to this Terms of Use. To
        ensure that You are aware of the changes, please review this Terms of
        Use and all the documents referred to hereunder periodically. 2.
        Definition and Interpretation </li><li>  In this Terms of Use, unless the
        contrary intention appears and/or the context otherwise requires,
        capitalized terms defined by: (i) inclusion in quotations and/ or
        parenthesis have the meanings so ascribed; and (ii) the following terms
        shall have the meanings assigned to them herein below: </li> <li>  “Applicable
        Law” includes all applicable Indian statutes, enactments, acts of the
        state legislature or parliament, laws, ordinances, rules, bye-laws,
        regulations, notifications, guidelines, directions, directives and
        orders of any governmental authority, statutory authority, board, any
        implementing regulation or interpretation issued thereunder including
        any successor Applicable Law; </li> <li>  “Applicant” shall mean You or any
        individual person above the age of [18 (Eighteen)] years, using an
        operational mobile phone connection, who applies either on his
        ownorapproaches the Company, for availing the Company Services in the
        capacity of being an Agent (Retailer/Distributor/Master
        Distributor/Super Distributor); </li><li>  “Company Rules” shall mean policies
        issued by the Company with respect to its strategic business partners,
        retail partners, distributors, Consumers including but not limited to
        the policy on the code of conduct and other management policies as
        issued on the Website, Platform and/ or otherwise and amended from time
        to time;</li><li>  “Consumer” shall mean an Applicant who upon submission of the
        AF and upon completion of necessary formalities, is found to be eligible
        by Company, to avail the Company Services and has opened a Membership
        Account; </li><li>  “Governmental Authority” means any nation, state, sovereign,
        or government, any federal, regional, state, local or political
        subdivision and any entity exercising executive, legislative, judicial,
        regulatory or administrative functions of or pertaining to government,
        constitutionally established and having jurisdiction over any of the
        parties (to the extent relevant to the transactions contemplated hereby)
        or the assets or operations of any of the foregoing or the transactions
        contemplated hereby; “INR” or “RS” means Indian Rupees, the lawful
        currency of the Republic of India; </li><li>  “Intellectual Property” shall mean
        all intellectual property used for the purpose of or in association with
        or in relation to providing the Company Services utilising the Platform
        and includes without limitation, (a) Software, operating manuals,
        software code, program, instructions, specifications, processes, input
        methods, data or information used in relation to, in association with or
        for the operation of the software installed by Company ; (b) the
        trademarks, service marks, trade names, business names, logos, symbols,
        styles, colour combinations used by Company during the course of its
        business and all depictions, derivations and representations thereof;
        (c) all promotional material including without limitation,
        advertisements, literature, graphics, images, content and the ‘look and
        feel’ of all of the above; and (d) all techniques, formulae, patterns,
        compilations, processes, inventions, practices, methodology, techniques,
        improvement, utility model, procedures, designs, skills, technical
        information, notes, experimental results, service techniques, samples,
        specifications of the products or services, labelling specifications,
        rights on software, and any other knowledge or know-how of any nature
        whatsoever; </li><li>  “KYC Guidelines” of “KYC” shall mean the Know Your
        Customer (KYC) guidelines as set forth by Governmental Authority
        including RBI;Applicant has to provide self-attested copies of valid
        Proof of Identity, Proof of Address and relevant KYC details as per the
        KYC guidelines; </li><li>  “Person” shall mean any individual (including personal
        representatives, executors or heirs of a deceased individual) or legal
        entity, including but not limited to, any partnership, joint venture,
        corporation, trust, unincorporated organization, limited liability
        company, limited liability partnership or Governmental Authority; </li><li> 
        “Platform” shall mean the Software accessible through the Website; </li> <li>  
        “RBI” shall mean the Reserve Bank of India; </li><li>  “Registered Mobile Number”
        shall mean the mobile number of the Consumer registered with the Company
        at the time of opening up / setting up of the Membership Account;</li><li> 
        “Software” shall include custom built software that is owned by Company,
        or software that has been licensed from third party suppliers by Company
        and in relation to which Company has obtained the right to sub license
        from such third-party suppliers, as modified/ replaced from time to
        time, that enables Retail Partner to utilize Company Services on
        communication devices such as computers, mobile phones and other
        handheld wireless devices etc. as identified by Company from time to
        time; </li><li>  "Related Entities" shall mean any parent company, subsidiaries,
        affiliated corporations, partnerships, or joint ventures of the Company;
        and/or upon instruction of the Consumer on the Platform and/or Website
        utilizing the Company Services; </li><li>  “Tax” or “Taxes” shall mean any and
        all taxes, cess, levies, imposts, duties, charges, deposits, fees,
        deductions or withholdings that are, or that are to be, imposed, levied,
        collected, withheld or assessed, together with any and all interest,
        penalties, claims or other liabilities arising under or relating
        thereto; </li><li>  “You” or “Your”; shall mean any natural or legal person who
        has access to and is using the Platform for the purpose of opening a
        Membership Account in accordance with the terms of this Terms of Use,
        including but not limited to such users who have not created a
        Membership Account and are accessing the Platform without such a
        Membership Account; and </li><li>  “Website” shall mean and include
        www.paynearby.in, mobile application of Company, any successor website/
        applications, any website of Related Entity or any other channel
        facilitated and permitted by Company including but not limited to App,
        any other digital medium including phone, displays, emails, social media
        interfaces, messaging interfaces, wallet, payment intermediaries using
        Company’s interface. </li><li>  Interpretation - The terms referred to in this
        Agreement shall, unless defined otherwise or inconsistent with the
        context or meaning thereof, bear the meanings ascribed to them under the
        relevant statute/legislation.</li><li>  Reference to statutory provisions shall
        be construed as meaning and including references also to any amendment
        or reenactment for the time being in force and to all statutory
        instruments or orders made pursuant to such statutory provisions. </li><li> 
        Words denoting the singular shall include the plural and words denoting
        any gender shall include all genders. </li><li>  Headings, subheadings, titles,
        subtitles to clauses, sub-clauses and paragraphs are for information
        only and shall not form part of the operative provisions of this
        Agreement or the annexures hereto and shall be ignored in construing the
        same.</li><li>  References to days, months and years are to calendar days,
        calendar months and calendar years, respectively. </li><li>  Unless otherwise
        specified, time periods within or following which any payment is to be
        made or act is to be done shall be calculated by excluding the day on
        which the period commences and includingss the day on which the period
        ends and by extending the period to the next day of the last day of such
        period is not a day of which Company and/or banking institutions in
        India are open for general business;</li><li>  Any reference to “writing” shall
        include printing, typing, lithography, transmissions by facsimile or in
        electronic form (including e-mail) and other means of reproducing words
        in visible form including but not limited to any instructions provided
        by the Company in the Website and/or the Platform.</li><li>  The words “include”
        and “including” are to be construed without limitation. </li><li>  No provisions
        shall be interpreted in favor of, or against, any party by reason of the
        extent to which such party or its counsel participated in the drafting
        hereof or by reason of the extent to which any such provision is
        inconsistent with any prior draft here of. 3. CONDITIONS OF USE</li><li>  This
        Agreement applies to any person who accesses or avails any service on
        our platform for any purpose. It also applies to any legal entity which
        may be represented by any person who accesses or avails any service on
        the Website, under actual or apparent authority. Agent(s) may use this
        Website to avail the company services available on the website.</li><li> 
        Consumer agrees and undertakes that it shall utilize the Company
        Services only through the Membership Account in accordance with these
        Terms and Conditions and relevant regulations.</li><li>  The Agreement shall be
        valid for a period of one year.</li><li>  Company Services can only be availed
        by an individual who is above the age of 18 (Eighteen)years.</li><li>  To avail
        of the Company Services from the website, the Agents shall only approach
        the administrator to activate the account.</li><li>  The Consumer, at the time
        of submission of the AF, confirms that the Consumer is of sound mind.</li><li> 
        The Consumer must ensure that the Registered Phone Number has an active
        connection at all times. The Consumer shall immediately inform Company
        in writing in the event of any change, cancellation, disruption,
        termination or surrender of the Registered Phone Number </li><li>  while joining
        the company, the agent is required to give the name of the nominee. In
        case of the death of agent, the money in the wallet if any shall be
        transferred to the nominee on presenting the death certificate</li><li>  The
        agent is required to pay the monthly charges timely, failing in doing so
        the company shall hold the power to deduct the charges from the main
        wallet and to impose a penalty of Rs. 20/day, as deemed fit. </li><li>  The
        Website is an online platform that Agent(s) utilize to independently
        avail the services offered i.e. Aadhar Banking, Bill Payment and
        Recharges, Pan etc. SEVENUNIQUE is not and cannot be a party to any
        transaction or dispute between Agents on the Platform.</li><li>  The Consumer
        acknowledges that any information provided to Company with the intention
        of securing the Membership Account shall vest with Company (as the case
        may be), and may be used by Company, at its discretion, for any purpose
        consistent with Applicable Laws.</li><li>  The Consumer acknowledges that any
        information submitted by the Consumer while using the Company Services,
        Platform, Website or the Membership Account may be shared with third
        parties by Company inter- alia, to facilitate the provision of Company
        Services.</li><li>  The Consumer shall not utilize Company Services, Platform,
        Website or the Membership Account for any purpose that might be
        construed as contrary or repugnant to Applicable Laws, public policy or
        for any purpose that is contrary to Company Rules or might prejudice the
        goodwill of Company and/or Related Entities.</li><li>  The Consumer acknowledges
        and understands that it is solely responsible for satisfying himself
        with notifications that the Transactions have been successfully
        completed. Company however will not be held responsible for
        notifications not reaching the Consumer.</li><li>  The Company does not make any
        representations or warranties regarding specifics of the services
        proposed to be sold, offered to be sold or availed on the Platform. It
        does not implicitly or explicitly support or endorse the sale or
        purchase of any products and services on the Platform. The Company
        accepts no liability for any errors or omissions of third parties in
        relation to the services.</li><li>  The Company is not responsible for any
        non-performance or breach of any contract/ agreement/ understanding in
        whatever form stated, between the service provider and agents. The
        Company shall not and is not required to mediate or resolve disputes or
        disagreements between the customers and agents.</li><li>  The Company shall not
        be held responsible for any wrong transaction undertaken by the agent
        like Recharge, DMT etc. and thereby shall not be liable to refund any
        amount whatsoever.</li><li>  The Company shall not be responsible to send the ID
        and password to the agent, if the agent forgets the ID, he must follow
        the process of recovering the ID password, which includes clicking on
        the ‘forget and password’ option after which they shall get the link on
        Email and SMS notification.</li><li>  In the event an agent wishes to cancel his
        subscription plan, they shall have to click on the ‘cancel now’ option
        and send a notice to the company, the company after receiving the notice
        shall cancel such subscription in 7 days after deducting the charges
        till that day.</li><li>  If the agent is found involved in a fraudulent
        transaction, the company shall give a warning with a 7-day opportunity
        to clear the matter. The company shall hold the power to impose penalty,
        1000/day charges for customer problem, ID shall be suspended without any
        prior information or notice and FIR shall be filed against such agent.</li><li> 
        In the event an agent registers through their own ID, complete all the
        KYC process and accept all the terms and conditions, the company shall
        not be held responsible for any refundable amount.</li><li>  There are certain
        free service links available on the website of the company, which open
        on some other website on clicking. The company shall not be held liable
        for any word done by it.</li><li>  In the event, an agent shares his password to
        some other person or if their password/phone gets stolen, and there is
        some transaction from such phone, the company shall not be held
        responsible for it. The customer is required to call the customer care
        and stop the services.</li><li>  SEVENUNIQUE shall be held responsible for the
        amount deposited in its own bank account only, and shall be absolved of
        the responsibility if any agent deposits amount in any individual
        account.</li><li>  If in case the associate bank holds the payment, then the
        company has the right to hold agents amount and the company shall not be
        in any case responsible for any penalty & interest over the amount and
        the company is only liable to pay to agent the real amount received from
        the bank.</li><li>  The Agent(s) therefore agree that, The Company shall not be
        liable or responsible for any damages, liabilities, costs, harms,
        inconveniences, business disruptions or expenditures of any kind that
        may occur/arise as a result of or in connection with any Transaction
        Risks. Agent(s) are solely responsible for all of the terms and
        conditions of the transactions conducted on, through or as a result of
        use of any content, information or any other material provided on the
        Website, including, without limitation, terms regarding payment,
        returns, warranties, shipping, insurance, fees, taxes, title, licenses,
        fines, permits, handling, transportation and storage. In the event of a
        dispute with any party to a transaction, Agent(s) agrees to release and
        indemnify the Company (and Our agents, affiliates, directors, officers
        and employees) from all claims, demands, actions, proceedings, costs,
        expenses and damages (including without limitation any actual, special,
        incidental or consequential damages) arising out of or in connection
        with such transaction. 4. CHANGES OF TERMS Company shall at their sole
        discretion, and without notice to the Consumer, alter, modify or amend
        these Terms of Use from time to time and the same shall be updated and
        displayed by Company on its Platform/ Website. The Customer is expected
        to keep himself/herself updated with the amendments to the Terms of Use.
        Any transaction done subsequent to the amendment of the Terms of Use
        will be considered as Customer’s acceptance of the amended Terms of Use.
        Company may modify, terminate and/or suspend Company Services anytime
        with or without prior notice, due to any changes in internal policies,
        rules, regulations and laws set by relevant authorities/regulators. 5.
        LINK TO OTHER WEBSITES Company may provide links to other websites that
        are maintained by third parties on the Website. These links are provided
        for your convenience only and the provision of these links does not mean
        that Company endorses these websites or the products and services they
        provide. You acknowledge and agree that Company is not responsible
        and/or liable for any information/ content or any products/services
        available on these third-party websites. 6. SEVERABILITY If any part of
        these Terms of Use is adjudged illegal or inoperable for any reason, the
        same shall be severed from the remainder of this document and only that
        portion of this document that is specifically adjudged illegal or
        inoperable shall cease to govern the relationship between Company and
        the Consumer. 7. OWNERSHIP AND PROPERIETARY RIGHTS The Consumer agrees
        that he/she shall have no claims/rights of whatsoever nature in the
        Intellectual Property including but not limited to any intellectual
        property rights arising out of and in connection with Platform, Website
        and/or the Company Services. The Consumer further undertakes that he/she
        shall not attempt to modify, alter, obscure, translate, disassemble,
        decompile or reverse engineer the Software underlying application or
        create any derivative product based on the Software. 8. FORCE MAJEURE
        Company shall inform the Consumer of the existence of a Force Majeure
        Event and shall consult together to find a mutually acceptable solution.
        “Force Majeure Event” means any event due to any cause beyond the
        reasonable control of Company and/or Financial Service Provider,
        including, without limitation, unavailability of any communication
        system, breach or virus in the processes or payment mechanism, sabotage,
        fire, flood, explosion, acts of God, civil commotion, strikes or
        industrial action of any kind, riots, government lockdown, insurrection,
        war, acts of government, computer hacking, unauthorized access to
        computer data and storage device, computer crashes, breach of security
        and encryption, etc. Company shall not be liable for any failure to
        perform any of its obligations under these Terms of Use or the specific
        terms and conditions if the performance is prevented, hindered or
        delayed by a Force Majeure Event and in such case its obligations shall
        be suspended for so long as the Force Majeure Event continues. 9.
        LIMITATION OF LIABILITY</li><li>  The Consumer understands and acknowledges that
        the work of the distributor is target based and commission will be
        released according to % of the target achieved.</li><li>  The Consumer
        understands and acknowledges that in the event a distributor fails to
        complete his target, he/she shall have to continue working for a period
        of 90 days. After the completion of such time period, the company shall
        close the registration and hold his commission. </li><li> If the distributor
        closes his agreement with the company, he shall be liable to return all
        the accessories as provided by the company. In case the accessories
        received are not in good condition, the amount to that effect shall be
        adjusted from the security fees of such distributor.</li><li>  The Company holds
        the power to change or stop its services like commission structure,
        offer for service, rate for package etc. at any point in time without
        giving any information to the agent.</li><li>  If the transaction made by the
        customer is found fraudulent, the company shall have the power to hold
        the amount until the case is resolved.</li><li>  The Consumer understands and
        acknowledges that Name, logo, brand name etc. is the exclusive
        intellectual property of the company, and that no agent may use the same
        on any social media or any other platform without the express permission
        of the company.</li><li>  The Consumer understands and acknowledges that the
        services provided by the Company including Company Services is provided
        on an “as is” and “as available” basis and that the use of Company
        Services by Consumers is at its own risk.</li><li>  The Consumer further
        understands and acknowledge that the Company shall in no way be
        responsible for any acts or omissions on part of Retail Agents.</li><li> 
        Without prejudice to the aforesaid, in no event shall our total
        cumulative liability to the Consumer for any and all claims relating to
        or arising out of the Consumer’s use of the Platform/ Company Services
        and/or Website, regardless of the form of action, exceed the amount of
        service fee chargeable by the Company for its services.. In no event
        shall Company be liable to the Consumer (or to any third party claiming
        under or through the Consumer) for any indirect, special, incidental,
        consequential or exemplary damages arising from the Consumer’s use of,
        or inability to use, the Platform/ Company Services and/or Website.
        These exclusions apply to any claims for lost profits, lost data, loss
        of goodwill, work stoppage, bugs and errors, computer failure or
        malfunction, any other commercial damages or losses, even if Company new
        or should have known of the possibility of such damages. 10. Charges The
        company reserves the right to change its fee policy from time to time.
        Changes to the fee policy shall be posted on the Platform and such
        changes shall automatically become effective immediately after they are
        posted on the Platform. Unless otherwise stated, all fees shall be
        quoted in Indian Rupees (INR) and payable to SEVENUNIQUE. Agent shall be
        solely responsible for compliance with all applicable laws for making
        payments to SEVENUNIQUE. Agent hereby agrees that the company shall have
        the right to set off any amounts due and payable by Agent to the company
        against any payments due from the company to Agent. 11. PRIVACY POLICY &
        DATA PROTECTION Company may suspend or cease the operation of the
        Platform, Website and/or the Company Services for any reason with or
        without giving any prior notice. Company reserves the right to change
        the content on the Website and/or suspend or change the product or
        Financial Services offered on the Website and/or the Platform. The
        Consumer agrees that in any mentioned case, he/she will use the Website
        and/or Platform t his/her own risk and Company is not responsible for
        any loss or damage. 12. DISCLAIMER Company may suspend or cease the
        operation of the Platform, Website and/or the Company Services for any
        reason with or without giving any prior notice. Company reserves the
        right to change the content on the Website and/or suspend or change the
        product or Financial Services offered on the Website and/or the
        Platform. The Consumer agrees that in any mentioned case, he/she will
        use the Website and/or Platform t his/her own risk and Company is not
        responsible for any loss or damage. 13. PRIVACY POLICY All of the
        information that Company collects from the Consumer, such as
        registration details, beneficiary account details and debit/credit card
        information etc., is subject to the provisions of this Terms of Use. 14.
        WAIVER The failure to exercise or delay in exercising a right or remedy
        provided by this Terms of Use or by law does not constitute a waiver of
        the right or remedy or the waiver of other rights or remedies. No single
        or partial exercise of a right or remedy provided by this Terms of Use
        or by law prevents further exercise of the right or remedy or the
        exercise of another right or remedy. Any waiver must be in writing and
        signed by the party sought to be bound. 15. ASSIGNMENT This Terms of Use
        or any right or interest herein, shall not be assignable by the
        Consumer. Company shall be free to assign its rights,interest and
        obligations under this Terms of Use to any Person including but not
        limited to Related Entities. 16. MODE OF COMMUNICATION When Agentuse the
        Platform or send emails or other data, information or communication to
        us, Agent hereby agrees and understand that Agent are communicating with
        Us through electronic records and Agent consent to receive
        communications via electronic records from Us periodically and as and
        when required. We may communicate with Agent by email or by such other
        mode of communication, electronic or otherwise. 17. Notices All notices
        or demands to or upon the Company shall be effective if in writing and
        shall be deemed to be duly made when sent to SEVENUNIQUE,1st floor,
        C-10, Sector-9, Chitrakoot Marg, Vaishali Nagar, Jaipur (RAJ.) - 302021.
        All notices or demands to or upon Agent(s) shall be effective if either
        delivered personally, sent by courier, certified mail, by facsimile or
        email to the last-known correspondence, fax or email address provided by
        the Agent(s) on the Website, or by posting such notice or demand on an
        area of the Website that is publicly accessible without a charge. Notice
        to Agent(s) shall be deemed to be received by such Agent(s) if and when
        Website is able to demonstrate that communication, whether in physical
        or electronic form, has been sent to such Agent(s), or immediately upon
        Website’s posting such notice on an area of the Website that is publicly
        accessible without charge. 18. GOVERNING LAW AND DISPUTE RESOLUTION This
        Agreement will be governed by and construed in accordance with the laws
        of the Republic of India. The courts in Jaipur shall have exclusive
        jurisdiction over any dispute arising from use of Company Service,
        Platform and/or Website. Any dispute under this Agreement between the
        Company and the Agents shall be referred to Arbitration to be conducted
        as per the Arbitration and Conciliation Act, 1996, as applicable at the
        time including such amendments that may be made, by a SingleArbitrator
        to be appointed at the sole discretion of the Website. The seat of
        arbitration shall be at Jaipur, India and the language of the
        Arbitration shall be English. 19. TERM AND SURVIVAL This Terms of Use is
        effective until: (a) terminated by Company and Company reserves the
        right to terminate this Terms of Use; and/or (ii) upon Consumer ceasing
        to hold Membership Account. The provisions of this Terms of Use which by
        their nature are intended to survive the termination or expiration of
        this Terms of Use. 20. ENTIRE AGREEMENT The "Terms" including the
        Privacy Policy constitutes the entire agreement between You and
        SEVENUNIQUE and governs Your access and use of the Services, superseding
        any prior agreements between You and SEVENUNIQUE with respect to the
        Website and Services. 21. SELF-DECLARATION I hereby declare that I have
        read and understood all terms and conditions of this Terms of Use.
        Accordingly, I would wish to use the Platform, Website and/or avail the
        Company Services. I hereby give my consent to be bound by provisions of
        this Terms of Use.</li>
      </ul>
      </div>
    </>
  );
}

export default TC;
