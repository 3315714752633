import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Navbar from './component/Navbar/NavbarComponent';
import Footer  from "./component/Footer/footer";
import Home from './Pages/Home/home';
import AboutUS from "./Pages/About/about";
import Services from "./Pages/Services/services";
import Teams from "./Pages/Team/Team";
import ContactUS from "./Pages/Contact/contact";
import TC from "./Pages/Terms&Conditions/TC";
import RP from "./Pages/RefundPolicy/RP";
import PP from "./Pages/PrivacyPolicy/PP";
// import AppHeader from './components/header';
// import AppHero from './components/hero';
// import AppAbout from './components/about';
// import AppServices from './components/services';
// import AppWorks from './components/works';
// import AppTeams from './components/teams';
// import AppTestimonials from './components/testimonials';
// import AppPricing from './components/pricing';
// import AppBlog from './components/blog';
// import AppContact from './components/contact';
// import AppFooter from './components/footer';

function App() {
  return (
    <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/Aboutus" element={<AboutUS />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Teams" element={<Teams />} />
        <Route path="/ContactUs" element={<ContactUS />} />
        <Route path="/Terms And Conditions" element={<TC />} />
        <Route path="/Refund Policy" element={<RP />} />
        <Route path="/Privacy Policy" element={<PP />} />
        {/* 
        <Route path="/ContactUs" element={<ContactUS />} />
        
        <Route path="/TrainingPrograms" element={<TrainingPrograms />} />
      
        <Route path="/Careers" element={<Careers />} />
        <Route path="/Industries" element={<Industries />} />
        <Route path="/TP" element={<TP/>}/> */}
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
