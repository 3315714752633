import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import logoWhite from "../../assest/Images/whiteLogo.svg";
import logoColor from "../../assest/Images/logo.svg";
import "./style.css";

function NavbarComponent() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    let timeoutId;

    const handleScroll = () => {
      setScrolled(true);

      // If there's a timeout already, clear it
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      // Set a new timeout to revert back to original state after 10 seconds
      
      // timeoutId = setTimeout(() => {
      //   setScrolled(false);
      // }, 10000);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      // Clear the timeout when component unmounts
      clearTimeout(timeoutId);
    };
  }, []);

  // Reset scroll state when scrolling back to top
  useEffect(() => {
    const handleScrollTop = () => {
      if (window.scrollY === 0) {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScrollTop);

    return () => {
      window.removeEventListener("scroll", handleScrollTop);
    };
  }, []);

  return (
    <>
      <Navbar expand="lg" className="navbar" style={{ backgroundColor: scrolled ? "white" : "transparent" }}>
        <Container className="navbar-container">
          <Navbar.Brand href="/" className="fw-bolder logo-write">
            <img src={scrolled ? logoColor : logoWhite} alt="logo" className="navbar-logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link className="navbarMenu" href="/" style={{ color: scrolled ? "black" : "white" }}>
                Home
              </Nav.Link>
              <Nav.Link className="navbarMenu" href="/Aboutus" style={{ color: scrolled ? "black" : "white" }}>
                About
              </Nav.Link>
              <Nav.Link className="navbarMenu" href="/Services" style={{ color: scrolled ? "black" : "white" }}>
                Services
              </Nav.Link>
              <Nav.Link className="navbarMenu" href="/Teams" style={{ color: scrolled ? "black" : "white" }}>
                Teams
              </Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link className="navbarMenu" href="/ContactUs" style={{ color: scrolled ? "black" : "white" }}>
                Contact
              </Nav.Link>
              <Nav.Link className="navbarMenu" href="https://panel.7unique.in" style={{ color: scrolled ? "black" : "white" }}>
                Signup
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarComponent;
